<template>
<div
    v-if="courseTransferVisible"
    class="modal modal-sticky-bottom-right modal-sticky-lg show"
    role="dialog"
    data-backdrop="false"
    aria-modal="true"
    style="display: block"
>
    <div class="modal-dialog">
        <div class="modal-content kt-inbox">
            <div class="kt-portlet">
                <div class="kt-portlet__head">
                    <div class="kt-portlet__head-label">
                        <span class="kt-portlet__head-icon kt-hidden">
                            <i class="la la-gear" />
                        </span>
                        <h3 class="kt-portlet__head-title">
                            Course Data Transfer
                        </h3>
                    </div>
                    <div class="kt-portlet__head-label float-right">
                        <a
                            href="#"
                            class="btn btn-icon"
                            @click.stop.prevent="closeModal"
                        >
                            <i class="la la-close" />
                        </a>
                    </div>
                </div>
                <div class="kt-portlet__body">
                    <div class="kt-section">
                        <div class="form-group row">
                            <div class="col-lg-6">
                                <label>From Course</label>
                                <select
                                    v-model="fromCourse"
                                    class="form-control"
                                    :disabled="confirming || saving"
                                >
                                    <option
                                        v-for="(course) in sortedCourses"
                                        :key="`fromCourseSectionId_${course.courseSectionId}`"
                                        :value="course"
                                    >
                                        {{ `${course.extCourseSectionId}: ${course.name}` }}
                                    </option>
                                </select>
                                <span class="form-text text-muted">Data in course will not be deleted</span>
                            </div>
                            <div class="col-lg-6">
                                <label class="">To Course</label>
                                <select
                                    v-model="toCourse"
                                    class="form-control"
                                    :disabled="confirming || saving"
                                >
                                    <option
                                        v-for="(course) in sortedCourses"
                                        :key="`toCourseSectionId_${course.courseSectionId}`"
                                        :value="course"
                                    >
                                        {{ `${course.extCourseSectionId}: ${course.name}` }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>Data to Transfer</label>
                            <div class="row ml-2">
                                <div class="kt-checkbox-list col-5">
                                    <label class="kt-checkbox">
                                        <input
                                            v-model="options.includeStudents"
                                            type="checkbox"
                                            :disabled="confirming || saving"
                                        > Include Students
                                        <span />
                                    </label>
                                    <label class="kt-checkbox">
                                        <input
                                            v-model="options.includeAttendance"
                                            type="checkbox"
                                            :disabled="confirming || saving"
                                        > Include Attendance
                                        <span />
                                    </label>
                                    <label class="kt-checkbox">
                                        <input
                                            v-model="options.includeReportCardGrades"
                                            type="checkbox"
                                            :disabled="confirming || saving"
                                        > Include Report Card Grades
                                        <span />
                                    </label>
                                </div>
                                <div class="kt-checkbox-list col-4">
                                    <label class="kt-checkbox">
                                        <input
                                            v-model="options.includeAssignments"
                                            type="checkbox"
                                            :disabled="confirming || saving"
                                            @change="includeAssignmentsChanged($event)"
                                        > Include Assignments
                                        <span />
                                    </label>
                                    <label class="kt-checkbox">
                                        <input
                                            v-model="options.includeGrades"
                                            type="checkbox"
                                            :disabled="confirming || saving"
                                            @change="includeGradesChanged($event)"
                                        > Include Grades
                                        <span />
                                    </label>
                                </div>
                            </div>
                            <span v-if="!options.includeStudents" class="form-text text-muted">
                                Students not on the "To Course" roster will not have their data transferred.
                            </span>
                        </div>
                        <div class="pt-3">
                            <span v-if="!confirming" class="row">
                                <span class="col-9">
                                    <span>
                                        Course data transfers provide the best results when selecting all options into empty courses
                                    </span>
                                </span>
                                <span class="col-3">
                                    <button
                                        class="btn btn-primary pull-right"
                                        :disabled="!canTransfer"
                                        @click.stop.prevent="confirming = true"
                                    >
                                        Copy Data
                                    </button>
                                </span>
                            </span>
                            <span v-else class="row align-items-center">
                                <span class="col-8">
                                    <span v-if="options.includeAssignments">
                                        Assignments being transferred will ALWAYS be re-created in the "To Course" even for assignments with the same name/data,
                                        potentially causing duplicate assignments.
                                    </span>
                                </span>
                                <span class="col-4">
                                    <button
                                        v-if="!saving"
                                        class="btn btn-danger"
                                        :disabled="saving"
                                        @click.stop.prevent="confirming = false"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        class="btn btn-primary ml-2"
                                        :class="{'pull-right kt-spinner kt-spinner--sm kt-spinner--light': saving}"
                                        :disabled="!canTransfer || saving"
                                        @click.stop.prevent="transferCourse"
                                    >
                                        {{ saving ? 'Copying' : 'Confirm' }}
                                    </button>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import { mapState } from 'vuex';
import * as network from '../network';

export default {
    name: 'CourseDataTransferModal',
    components: {
    },
    mixins: [],
    props: {
        courseTransferVisible: {
            type: Boolean,
            required: true,
        },
        closeCourseTransfer: {
            type: Function,
            required: true,
        },
        courses: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            saving: false,
            confirming: false,
            fromCourse: null,
            toCourse: null,
            options: {
                includeStudents: false,
                includeAttendance: false,
                includeAssignments: false,
                includeGrades: false,
                includeReportCardGrades: false,
            },
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            school: (state) => state.user.school,
        }),
        sortedCourses() {
            const { courses } = this;
            return courses.sort((a, b) => `${a.extCourseSectionId || ''}: ${a.name || ''}`.localeCompare(`${b.extCourseSectionId || ''}: ${b.name || ''}`));
        },
        canTransfer() {
            const { fromCourse, toCourse, options } = this;
            if (!fromCourse || !toCourse) return false;
            if (fromCourse.courseSectionId == toCourse.courseSectionId) return false;

            let optionSelected = false;
            Object.keys(options).forEach((key) => {
                if (options[key]) optionSelected = true;
            });
            return optionSelected;
        },
    },
    methods: {
        closeModal() {
            if (this.saving) return;
            this.saving = false;
            this.confirming = false;
            this.fromCourse = null;
            this.toCourse = null;
            this.options = {
                includeStudents: false,
                includeAttendance: false,
                includeAssignments: false,
                includeGrades: false,
                includeReportCardGrades: false,
            };
            this.closeCourseTransfer();
        },
        includeAssignmentsChanged(event) {
            this.$nextTick(() => {
                if (!this.options.includeAssignments) this.options.includeGrades = false;
            });
        },
        includeGradesChanged(event) {
            this.$nextTick(() => {
                if (this.options.includeGrades) this.options.includeAssignments = true;
            });
        },
        transferCourse() {
            const v = this;
            if (v.saving || !v.confirming || !v.canTransfer) return;
            v.saving = true;

            const {
                school, fromCourse, toCourse, options,
            } = v;
            const { schoolId, schoolTermId } = school;
            const {
                includeStudents, includeAttendance, includeAssignments, includeGrades, includeReportCardGrades,
            } = options;

            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                },
                body: {
                    options: {
                        fromCourseSectionId: fromCourse.courseSectionId,
                        toCourseSectionId: toCourse.courseSectionId,
                        includeStudents,
                        includeAttendance,
                        includeAssignments,
                        includeGrades,
                        includeReportCardGrades,
                    },
                },
            };

            network.courseSections.transferCourseSectionData(params, (err, res) => {
                v.saving = false;
                v.confirming = false;
                if (err) return v.showError(err);
                v.closeModal();
                if (toCourse.teachers.length === 0) v.$router.push({ name: 'CourseInfo', params: { extCourseSectionId: toCourse.extCourseSectionId } });
                else v.$router.push({ name: 'TeacherCourseInfo', params: { extCourseSectionId: toCourse.extCourseSectionId, schoolEmail: v.getFirstTeacherEmail(toCourse) } });
            });
        },
        getFirstTeacherEmail(course) {
            const [teacher] = course.teachers;
            return teacher ? teacher.schoolEmail : null;
        },
    },
};
</script>

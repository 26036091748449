var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "kt-subheader kt-grid__item",
          attrs: { id: "kt_subheader" },
        },
        [
          _c("div", { staticClass: "kt-container kt-container--fluid" }, [
            _c("div", { staticClass: "kt-subheader__main" }, [
              _vm.deviceType !== "laptop"
                ? _c(
                    "h3",
                    { staticClass: "kt-subheader__title" },
                    [
                      _c("SVGIcon", {
                        staticClass: "mt-2 mr-3",
                        attrs: { "hex-color": "#0f88ef", name: "courses" },
                      }),
                      _vm._v(" Courses "),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass:
                    "kt-input-icon kt-input-icon--right kt-subheader__search mt-1",
                },
                [
                  _c(
                    "b-form-input",
                    {
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        placeholder: "Filter...",
                        debounce: "500",
                      },
                      model: {
                        value: _vm.filter,
                        callback: function ($$v) {
                          _vm.filter = $$v
                        },
                        expression: "filter",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "kt-input-icon__icon kt-input-icon__icon--right",
                        },
                        [
                          _c("span", [
                            _c("i", { staticClass: "flaticon2-search-1" }),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "kt-subheader__toolbar" }, [
              _c("div", { staticClass: "kt-subheader__wrapper row" }, [
                _vm.deviceType !== "mobile" &&
                !_vm.$_userMixins_isGoogleDisabled
                  ? _c(
                      "a",
                      {
                        staticClass: "mr-3",
                        class: _vm.showLinked
                          ? "btn btn-success"
                          : "btn kt-subheader__btn-secondary",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            _vm.showLinked = !_vm.showLinked
                          },
                        },
                      },
                      [_vm._v(" Google Linked ")]
                    )
                  : _vm._e(),
                _vm.deviceType !== "mobile"
                  ? _c(
                      "a",
                      {
                        staticClass: "mr-3",
                        class: _vm.showDeleted
                          ? "btn btn-danger"
                          : "btn kt-subheader__btn-secondary",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            _vm.showDeleted = !_vm.showDeleted
                          },
                        },
                      },
                      [_vm._v(" Deleted Courses ")]
                    )
                  : _vm._e(),
                _c("div", { staticClass: "paginator-container mt-1" }, [
                  _c(
                    "div",
                    { staticClass: "kt-pagination kt-pagination--brand" },
                    [
                      _c("ul", { staticClass: "kt-pagination__links" }, [
                        _vm.deviceType !== "mobile"
                          ? _c(
                              "li",
                              { staticClass: "kt-pagination__link--first" },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.first.apply(null, arguments)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "fa fa-angle-double-left kt-font-brand",
                                    }),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c("li", { staticClass: "kt-pagination__link--next" }, [
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.previous.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-angle-left kt-font-brand",
                              }),
                            ]
                          ),
                        ]),
                        _c("li", { staticClass: "kt-pagination__link--prev" }, [
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.next.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-angle-right kt-font-brand",
                              }),
                            ]
                          ),
                        ]),
                        _vm.deviceType !== "mobile"
                          ? _c(
                              "li",
                              { staticClass: "kt-pagination__link--last" },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.last.apply(null, arguments)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "fa fa-angle-double-right kt-font-brand",
                                    }),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _vm.deviceType !== "laptop"
                        ? _c("div", { staticClass: "kt-pagination__toolbar" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.perPage,
                                    expression: "perPage",
                                  },
                                ],
                                staticClass: "form-control kt-font-brand",
                                staticStyle: { width: "60px" },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.perPage = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                },
                              },
                              [
                                _c("option", { domProps: { value: 100 } }, [
                                  _vm._v(" 100 "),
                                ]),
                                _c("option", { domProps: { value: 250 } }, [
                                  _vm._v(" 250 "),
                                ]),
                                _c("option", { domProps: { value: 500 } }, [
                                  _vm._v(" 500 "),
                                ]),
                              ]
                            ),
                            ["laptop", "desktop"].indexOf(_vm.deviceType) > -1
                              ? _c("div", { staticClass: "pagination__desc" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.currentPage == 1
                                          ? _vm.currentPage
                                          : (_vm.currentPage - 1) *
                                              _vm.perPage +
                                              1
                                      ) +
                                      " - " +
                                      _vm._s(
                                        _vm.perPage * _vm.currentPage >
                                          _vm.filteredCourses.length
                                          ? _vm.filteredCourses.length
                                          : _vm.perPage * _vm.currentPage
                                      ) +
                                      " of " +
                                      _vm._s(_vm.filteredCourses.length) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]),
                _vm.$_userMixins_isSchoolAdmin
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            value: "Transfer course data",
                            expression: "'Transfer course data'",
                            modifiers: { hover: true },
                          },
                        ],
                        staticClass: "dropdown dropdown-inline",
                        attrs: { title: "Transfer course data" },
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-label-primary btn-icon",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                _vm.courseTransferVisible = true
                              },
                            },
                          },
                          [_c("i", { staticClass: "fa fa-copy" })]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.canCreateCourse
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "b-tooltip",
                            rawName: "v-b-tooltip.hover",
                            value: "Create new course",
                            expression: "'Create new course'",
                            modifiers: { hover: true },
                          },
                        ],
                        staticClass: "dropdown dropdown-inline",
                        attrs: { title: "Create new course" },
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "btn btn-brand btn-icon",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                _vm.createCourseVisible = true
                              },
                            },
                          },
                          [_c("i", { staticClass: "flaticon2-plus" })]
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
        },
        [
          _c("div", { staticClass: "kt-portlet" }, [
            _vm.currentPage > 0
              ? _c(
                  "div",
                  { staticClass: "kt-portlet__body" },
                  [
                    _c("b-table", {
                      ref: "dataSet",
                      attrs: {
                        id: "data-set",
                        items: _vm.filteredCourses,
                        fields: _vm.fields,
                        "current-page": _vm.currentPage,
                        "per-page": _vm.perPage,
                        "primary-key": "courseSectionId",
                        fixed: "",
                        "selected-variant": "",
                        "show-empty": "",
                        hover: "",
                        responsive: "",
                        selectable: false,
                        "select-mode": "multi",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "cell(index)",
                            fn: function (data) {
                              return [
                                _vm._v(" " + _vm._s(data.index + 1) + " "),
                              ]
                            },
                          },
                          {
                            key: "table-colgroup",
                            fn: function (scope) {
                              return _vm._l(scope.fields, function (field) {
                                return _c("col", {
                                  key: field.key,
                                  style: field.style || "",
                                })
                              })
                            },
                          },
                          {
                            key: "cell(extCourseSectionId)",
                            fn: function (row) {
                              return [
                                _c("div", { staticClass: "pt-1 pl-0" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "course-section-badge-small",
                                      class: `color${_vm.getCourseColorIndex(
                                        row.item
                                      )}`,
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(row.item.extCourseSectionId) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "cell(name)",
                            fn: function (row) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "row" },
                                  [
                                    row.item.teachers.length == 0
                                      ? _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "course-title kt-font-bold kt-font-lg kt-widget4__title kt-widget4__title--light",
                                            attrs: {
                                              to: {
                                                name: "CourseInfo",
                                                params: {
                                                  extCourseSectionId:
                                                    row.item.extCourseSectionId,
                                                },
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(row.item.name) + " "
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "course-title kt-font-bold kt-font-lg kt-widget4__title kt-widget4__title--light",
                                            attrs: {
                                              to: {
                                                name: "TeacherCourseInfo",
                                                params: {
                                                  extCourseSectionId:
                                                    row.item.extCourseSectionId,
                                                  schoolEmail:
                                                    _vm.getFirstTeacherEmail(
                                                      row.item
                                                    ),
                                                },
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(row.item.name) + " "
                                            ),
                                          ]
                                        ),
                                  ],
                                  1
                                ),
                                row.item.nextMeeting
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "row text-muted kt-font-md text-nowrap text-truncate",
                                      },
                                      [
                                        _vm._v(
                                          " Next Meeting: " +
                                            _vm._s(
                                              row.item.nextMeeting.day?.substring(
                                                0,
                                                3
                                              ) || ""
                                            ) +
                                            ", Period " +
                                            _vm._s(
                                              row.item.nextMeeting.period || ""
                                            ) +
                                            ", Room " +
                                            _vm._s(
                                              row.item.nextMeeting.room || ""
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                          {
                            key: "cell(teachers)",
                            fn: function (row) {
                              return [
                                row.item.teachers.length == 0
                                  ? _c("div", { staticClass: "text-muted" }, [
                                      _vm._v(" No Teachers "),
                                    ])
                                  : _c(
                                      "div",
                                      { staticClass: "mt-1" },
                                      _vm._l(
                                        row.item.teachers,
                                        function (teacher) {
                                          return _c(
                                            "router-link",
                                            {
                                              key: `${teacher.schoolStaffId}_teacher`,
                                              attrs: {
                                                to: {
                                                  name: "TeacherCourseInfo",
                                                  params: {
                                                    extCourseSectionId:
                                                      row.item
                                                        .extCourseSectionId,
                                                    schoolEmail:
                                                      teacher.schoolEmail,
                                                  },
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "mb-2" },
                                                [
                                                  _c(
                                                    "UserAvatar",
                                                    {
                                                      staticStyle: {
                                                        width: "1.75rem",
                                                        height: "1.75rem",
                                                      },
                                                      attrs: {
                                                        rounded: true,
                                                        "avatar-user": teacher,
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "kt-media kt-media--sm",
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "kt-media kt-media--sm kt-media--primary mr-2",
                                                              staticStyle: {
                                                                width:
                                                                  "1.75rem",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    height:
                                                                      "1.75rem",
                                                                    "font-size":
                                                                      ".75rem",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        teacher.lastName.substring(
                                                                          0,
                                                                          2
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        `${teacher.lastName}, ${teacher.firstName}`
                                                      ) +
                                                      " "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                              ]
                            },
                          },
                          {
                            key: "cell(googleCourse)",
                            fn: function (row) {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "media-body mb-1 row" },
                                  [
                                    _c("div", [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "row align-items-center",
                                        },
                                        [
                                          row.item.googleCourse
                                            ? _c(
                                                "router-link",
                                                {
                                                  staticClass:
                                                    "course-title kt-font-bold kt-font-lg kt-widget4__title kt-widget4__title--light",
                                                  attrs: {
                                                    to: {
                                                      name: "CourseInfo",
                                                      params: {
                                                        extCourseSectionId:
                                                          row.item
                                                            .extCourseSectionId,
                                                      },
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        row.item.googleCourse
                                                          .googleCourseName
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3676826779
                      ),
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]
      ),
      _c("CreateCourseModal", {
        ref: "courseCreateModal",
        attrs: {
          "create-course-visible": _vm.createCourseVisible,
          "close-create-course": _vm.closeCreateCourse,
          courses: _vm.courses,
        },
      }),
      _c("CourseDataTransferModal", {
        ref: "courseDataTransferModal",
        attrs: {
          "course-transfer-visible": _vm.courseTransferVisible,
          "close-course-transfer": _vm.closeCourseTransfer,
          courses: _vm.courses,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
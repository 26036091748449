var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.courseTransferVisible
    ? _c(
        "div",
        {
          staticClass: "modal modal-sticky-bottom-right modal-sticky-lg show",
          staticStyle: { display: "block" },
          attrs: {
            role: "dialog",
            "data-backdrop": "false",
            "aria-modal": "true",
          },
        },
        [
          _c("div", { staticClass: "modal-dialog" }, [
            _c("div", { staticClass: "modal-content kt-inbox" }, [
              _c("div", { staticClass: "kt-portlet" }, [
                _c("div", { staticClass: "kt-portlet__head" }, [
                  _vm._m(0),
                  _c(
                    "div",
                    { staticClass: "kt-portlet__head-label float-right" },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-icon",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.closeModal.apply(null, arguments)
                            },
                          },
                        },
                        [_c("i", { staticClass: "la la-close" })]
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "kt-portlet__body" }, [
                  _c("div", { staticClass: "kt-section" }, [
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-lg-6" }, [
                        _c("label", [_vm._v("From Course")]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.fromCourse,
                                expression: "fromCourse",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { disabled: _vm.confirming || _vm.saving },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.fromCourse = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          _vm._l(_vm.sortedCourses, function (course) {
                            return _c(
                              "option",
                              {
                                key: `fromCourseSectionId_${course.courseSectionId}`,
                                domProps: { value: course },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      `${course.extCourseSectionId}: ${course.name}`
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                        _c("span", { staticClass: "form-text text-muted" }, [
                          _vm._v("Data in course will not be deleted"),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-lg-6" }, [
                        _c("label", {}, [_vm._v("To Course")]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.toCourse,
                                expression: "toCourse",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { disabled: _vm.confirming || _vm.saving },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.toCourse = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          _vm._l(_vm.sortedCourses, function (course) {
                            return _c(
                              "option",
                              {
                                key: `toCourseSectionId_${course.courseSectionId}`,
                                domProps: { value: course },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      `${course.extCourseSectionId}: ${course.name}`
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("Data to Transfer")]),
                      _c("div", { staticClass: "row ml-2" }, [
                        _c("div", { staticClass: "kt-checkbox-list col-5" }, [
                          _c("label", { staticClass: "kt-checkbox" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.options.includeStudents,
                                  expression: "options.includeStudents",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                disabled: _vm.confirming || _vm.saving,
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.options.includeStudents
                                )
                                  ? _vm._i(_vm.options.includeStudents, null) >
                                    -1
                                  : _vm.options.includeStudents,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.options.includeStudents,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.options,
                                          "includeStudents",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.options,
                                          "includeStudents",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.options,
                                      "includeStudents",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(" Include Students "),
                            _c("span"),
                          ]),
                          _c("label", { staticClass: "kt-checkbox" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.options.includeAttendance,
                                  expression: "options.includeAttendance",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                disabled: _vm.confirming || _vm.saving,
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.options.includeAttendance
                                )
                                  ? _vm._i(
                                      _vm.options.includeAttendance,
                                      null
                                    ) > -1
                                  : _vm.options.includeAttendance,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.options.includeAttendance,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.options,
                                          "includeAttendance",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.options,
                                          "includeAttendance",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.options,
                                      "includeAttendance",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(" Include Attendance "),
                            _c("span"),
                          ]),
                          _c("label", { staticClass: "kt-checkbox" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.options.includeReportCardGrades,
                                  expression: "options.includeReportCardGrades",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                disabled: _vm.confirming || _vm.saving,
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.options.includeReportCardGrades
                                )
                                  ? _vm._i(
                                      _vm.options.includeReportCardGrades,
                                      null
                                    ) > -1
                                  : _vm.options.includeReportCardGrades,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.options.includeReportCardGrades,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.options,
                                          "includeReportCardGrades",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.options,
                                          "includeReportCardGrades",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.options,
                                      "includeReportCardGrades",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(" Include Report Card Grades "),
                            _c("span"),
                          ]),
                        ]),
                        _c("div", { staticClass: "kt-checkbox-list col-4" }, [
                          _c("label", { staticClass: "kt-checkbox" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.options.includeAssignments,
                                  expression: "options.includeAssignments",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                disabled: _vm.confirming || _vm.saving,
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.options.includeAssignments
                                )
                                  ? _vm._i(
                                      _vm.options.includeAssignments,
                                      null
                                    ) > -1
                                  : _vm.options.includeAssignments,
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$a = _vm.options.includeAssignments,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.options,
                                            "includeAssignments",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.options,
                                            "includeAssignments",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.options,
                                        "includeAssignments",
                                        $$c
                                      )
                                    }
                                  },
                                  function ($event) {
                                    return _vm.includeAssignmentsChanged($event)
                                  },
                                ],
                              },
                            }),
                            _vm._v(" Include Assignments "),
                            _c("span"),
                          ]),
                          _c("label", { staticClass: "kt-checkbox" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.options.includeGrades,
                                  expression: "options.includeGrades",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                disabled: _vm.confirming || _vm.saving,
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.options.includeGrades
                                )
                                  ? _vm._i(_vm.options.includeGrades, null) > -1
                                  : _vm.options.includeGrades,
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$a = _vm.options.includeGrades,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.options,
                                            "includeGrades",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.options,
                                            "includeGrades",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.options,
                                        "includeGrades",
                                        $$c
                                      )
                                    }
                                  },
                                  function ($event) {
                                    return _vm.includeGradesChanged($event)
                                  },
                                ],
                              },
                            }),
                            _vm._v(" Include Grades "),
                            _c("span"),
                          ]),
                        ]),
                      ]),
                      !_vm.options.includeStudents
                        ? _c("span", { staticClass: "form-text text-muted" }, [
                            _vm._v(
                              ' Students not on the "To Course" roster will not have their data transferred. '
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "pt-3" }, [
                      !_vm.confirming
                        ? _c("span", { staticClass: "row" }, [
                            _vm._m(1),
                            _c("span", { staticClass: "col-3" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary pull-right",
                                  attrs: { disabled: !_vm.canTransfer },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      _vm.confirming = true
                                    },
                                  },
                                },
                                [_vm._v(" Copy Data ")]
                              ),
                            ]),
                          ])
                        : _c(
                            "span",
                            { staticClass: "row align-items-center" },
                            [
                              _c("span", { staticClass: "col-8" }, [
                                _vm.options.includeAssignments
                                  ? _c("span", [
                                      _vm._v(
                                        ' Assignments being transferred will ALWAYS be re-created in the "To Course" even for assignments with the same name/data, potentially causing duplicate assignments. '
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                              _c("span", { staticClass: "col-4" }, [
                                !_vm.saving
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-danger",
                                        attrs: { disabled: _vm.saving },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                            _vm.confirming = false
                                          },
                                        },
                                      },
                                      [_vm._v(" Cancel ")]
                                    )
                                  : _vm._e(),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary ml-2",
                                    class: {
                                      "pull-right kt-spinner kt-spinner--sm kt-spinner--light":
                                        _vm.saving,
                                    },
                                    attrs: {
                                      disabled: !_vm.canTransfer || _vm.saving,
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.transferCourse.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.saving ? "Copying" : "Confirm"
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-portlet__head-label" }, [
      _c("span", { staticClass: "kt-portlet__head-icon kt-hidden" }, [
        _c("i", { staticClass: "la la-gear" }),
      ]),
      _c("h3", { staticClass: "kt-portlet__head-title" }, [
        _vm._v(" Course Data Transfer "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "col-9" }, [
      _c("span", [
        _vm._v(
          " Course data transfers provide the best results when selecting all options into empty courses "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
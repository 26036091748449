<template>
<div>
    <div
        id="kt_subheader"
        class="kt-subheader kt-grid__item"
    >
        <div class="kt-container kt-container--fluid">
            <div class="kt-subheader__main">
                <h3
                    v-if="deviceType !== 'laptop'"
                    class="kt-subheader__title"
                >
                    <SVGIcon
                        hex-color="#0f88ef"
                        class="mt-2 mr-3"
                        name="courses"
                    />
                    Courses
                </h3>

                <div class="kt-input-icon kt-input-icon--right kt-subheader__search mt-1">
                    <b-form-input
                        v-model="filter"
                        type="text"
                        class="form-control"
                        placeholder="Filter..."
                        debounce="500"
                    >
                        <span class="kt-input-icon__icon kt-input-icon__icon--right">
                            <span>
                                <i class="flaticon2-search-1" />
                            </span>
                        </span>
                    </b-form-input>
                </div>
            </div>

            <div class="kt-subheader__toolbar">
                <div class="kt-subheader__wrapper row">
                    <a
                        v-if="deviceType !== 'mobile' && !$_userMixins_isGoogleDisabled"
                        class="mr-3"
                        :class="showLinked ? 'btn btn-success' : 'btn kt-subheader__btn-secondary'"
                        @click.stop.prevent="showLinked = !showLinked"
                    >
                        Google Linked
                    </a>
                    <a
                        v-if="deviceType !== 'mobile'"
                        class="mr-3"
                        :class="showDeleted ? 'btn btn-danger' : 'btn kt-subheader__btn-secondary'"
                        @click.stop.prevent="showDeleted = !showDeleted"
                    >
                        Deleted Courses
                    </a>
                    <div class="paginator-container mt-1">
                        <div class="kt-pagination kt-pagination--brand">
                            <ul class="kt-pagination__links">
                                <li
                                    v-if="deviceType !== 'mobile'"
                                    class="kt-pagination__link--first"
                                >
                                    <a
                                        href="#"
                                        @click.stop.prevent="first"
                                    ><i class="fa fa-angle-double-left kt-font-brand" /></a>
                                </li>
                                <li class="kt-pagination__link--next">
                                    <a
                                        href="#"
                                        @click.stop.prevent="previous"
                                    ><i class="fa fa-angle-left kt-font-brand" /></a>
                                </li>

                                <li class="kt-pagination__link--prev">
                                    <a
                                        href="#"
                                        @click.stop.prevent="next"
                                    ><i class="fa fa-angle-right kt-font-brand" /></a>
                                </li>
                                <li
                                    v-if="deviceType !== 'mobile'"
                                    class="kt-pagination__link--last"
                                >
                                    <a
                                        href="#"
                                        @click.stop.prevent="last"
                                    ><i class="fa fa-angle-double-right kt-font-brand" /></a>
                                </li>
                            </ul>
                            <div
                                v-if="deviceType !== 'laptop'"
                                class="kt-pagination__toolbar"
                            >
                                <select
                                    v-model="perPage"
                                    class="form-control kt-font-brand"
                                    style="width: 60px;"
                                >
                                    <option :value="100">
                                        100
                                    </option>
                                    <option :value="250">
                                        250
                                    </option>
                                    <option :value="500">
                                        500
                                    </option>
                                </select>

                                <div
                                    v-if="['laptop', 'desktop'].indexOf(deviceType) > -1"
                                    class="pagination__desc"
                                >
                                    {{ currentPage == 1 ? currentPage : (currentPage-1) * perPage + 1 }}
                                    -
                                    {{ perPage * currentPage > filteredCourses.length ? filteredCourses.length : perPage * currentPage }}
                                    of
                                    {{ filteredCourses.length }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="$_userMixins_isSchoolAdmin"
                        v-b-tooltip.hover="'Transfer course data'"
                        class="dropdown dropdown-inline"
                        title="Transfer course data"
                    >
                        <a
                            href="#"
                            class="btn btn-label-primary btn-icon"
                            @click.stop.prevent="courseTransferVisible = true"
                        >
                            <i class="fa fa-copy" />
                        </a>
                    </div>
                    <div
                        v-if="canCreateCourse"
                        v-b-tooltip.hover="'Create new course'"
                        class="dropdown dropdown-inline"
                        title="Create new course"
                    >
                        <a
                            href="#"
                            class="btn btn-brand btn-icon"
                            @click.stop.prevent="createCourseVisible = true"
                        >
                            <i class="flaticon2-plus" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet">
            <div
                v-if="currentPage > 0"
                class="kt-portlet__body"
            >
                <b-table
                    id="data-set"
                    ref="dataSet"
                    :items="filteredCourses"
                    :fields="fields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    primary-key="courseSectionId"
                    fixed
                    selected-variant=""
                    show-empty
                    hover
                    responsive
                    :selectable="false"
                    select-mode="multi"
                >
                    <template #cell(index)="data">
                        {{ data.index + 1 }}
                    </template>
                    <template #table-colgroup="scope">
                        <col
                            v-for="field in scope.fields"
                            :key="field.key"
                            :style="field.style || ''"
                        >
                    </template>

                    <template #cell(extCourseSectionId)="row">
                        <div class="pt-1 pl-0">
                            <div
                                class="course-section-badge-small"
                                :class="`color${getCourseColorIndex(row.item)}`"
                            >
                                {{ row.item.extCourseSectionId }}
                            </div>
                        </div>
                    </template>

                    <template #cell(name)="row">
                        <div class="row">
                            <router-link
                                v-if="row.item.teachers.length == 0"
                                :to="{name: 'CourseInfo', params: { extCourseSectionId: row.item.extCourseSectionId }}"
                                class="course-title kt-font-bold kt-font-lg kt-widget4__title kt-widget4__title--light"
                            >
                                {{ row.item.name }}
                            </router-link>
                            <router-link
                                v-else
                                :to="{name: 'TeacherCourseInfo', params: { extCourseSectionId: row.item.extCourseSectionId, schoolEmail: getFirstTeacherEmail(row.item) }}"
                                class="course-title kt-font-bold kt-font-lg kt-widget4__title kt-widget4__title--light"
                            >
                                {{ row.item.name }}
                            </router-link>
                        </div>
                        <div
                            v-if="row.item.nextMeeting"
                            class="row text-muted kt-font-md text-nowrap text-truncate"
                        >
                            Next Meeting: {{ row.item.nextMeeting.day?.substring(0, 3) || '' }},
                            Period {{ row.item.nextMeeting.period || '' }},
                            Room {{ row.item.nextMeeting.room || '' }}
                        </div>
                    </template>

                    <template #cell(teachers)="row">
                        <div
                            v-if="row.item.teachers.length == 0"
                            class="text-muted"
                        >
                            No Teachers
                        </div>
                        <div
                            v-else
                            class="mt-1"
                        >
                            <router-link
                                v-for="teacher in row.item.teachers"
                                :key="`${teacher.schoolStaffId}_teacher`"
                                :to="{name: 'TeacherCourseInfo', params: { extCourseSectionId: row.item.extCourseSectionId, schoolEmail: teacher.schoolEmail }}"
                            >
                                <div class="mb-2">
                                    <UserAvatar
                                        :rounded="true"
                                        :avatar-user="teacher"
                                        style="width: 1.75rem; height: 1.75rem;"
                                    >
                                        <div class="kt-media kt-media--sm">
                                            <a
                                                style="width: 1.75rem;"
                                                class="kt-media kt-media--sm kt-media--primary mr-2"
                                            >
                                                <span style="height: 1.75rem; font-size: .75rem;">
                                                    {{ teacher.lastName.substring(0, 2) }}
                                                </span>
                                            </a>
                                        </div>
                                    </UserAvatar>
                                    {{ `${teacher.lastName}, ${teacher.firstName}` }}
                                </div>
                            </router-link>
                        </div>
                    </template>

                    <template

                        #cell(googleCourse)="row"
                    >
                        <div class="media-body mb-1 row">
                            <div>
                                <div class="row align-items-center">
                                    <router-link
                                        v-if="row.item.googleCourse"
                                        :to="{name: 'CourseInfo', params: { extCourseSectionId: row.item.extCourseSectionId }}"
                                        class="course-title kt-font-bold kt-font-lg kt-widget4__title kt-widget4__title--light"
                                    >
                                        <!-- <span class="kt-badge kt-badge--success kt-badge--lg kt-badge--rounded mt-2">
                                                <i class="mt-2 socicon-google" />
                                            </span> -->
                                        {{ row.item.googleCourse.googleCourseName }}
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </template>
                </b-table>
            </div>
        </div>
    </div>

    <CreateCourseModal
        ref="courseCreateModal"
        :create-course-visible="createCourseVisible"
        :close-create-course="closeCreateCourse"
        :courses="courses"
    />

    <CourseDataTransferModal
        ref="courseDataTransferModal"
        :course-transfer-visible="courseTransferVisible"
        :close-course-transfer="closeCourseTransfer"
        :courses="courses"
    />
</div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapState } from 'vuex';
import UserAvatar from '../components/UserAvatar.vue';
import courseMixins from '../store/mixins/courseMixins';
import userMixins from '../store/mixins/userMixins';
import CreateCourseModal from '../components/CreateCourseModal.vue';
import CourseDataTransferModal from '../components/CourseDataTransferModal.vue';

export default Vue.extend({
    name: 'CourseList',
    components: {
        UserAvatar,
        CreateCourseModal,
        CourseDataTransferModal,
    },
    mixins: [
        userMixins,
        courseMixins,
    ],
    data() {
        return {
            currentPage: 1,
            filter: '',
            pageOptions: [100, 250, 500],
            perPage: 100,
            showDeleted: false,
            showLinked: false,
            createCourseVisible: false,
            courseTransferVisible: false,
            fields: [
                {
                    key: 'index',
                    label: '#',
                    sortable: false,
                    style: 'width: 24px;',
                    class: 'cell-class',
                },
                {
                    key: 'extCourseSectionId',
                    label: 'Course Id',
                    sortable: true,
                },
                {
                    key: 'name',
                    label: 'Name',
                    sortable: true,
                },
                {
                    key: 'teachers',
                    label: 'Course Teachers',
                    sortable: false,
                    style: 'width: 200px;',
                },
                {
                    key: 'googleCourse.googleCourseName',
                    label: 'Google Course',
                    sortable: true,
                },
            ],
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            googleCourses: (state) => state.database.googleCourses,
            deviceType: (state) => state.deviceType,
        }),
        courses() {
            const { googleCourses } = this;
            console.log('fired courses computed calc');
            return this.$_courseMixins_getReducedCourses().map((c) => {
                const course = { ...c };
                const { googleCourseId } = course;
                const googleCourse = googleCourses.find((gc) => googleCourseId && gc.googleCourseId === googleCourseId);
                course.googleCourse = googleCourse || null;
                return course;
            }).sort((a, b) => ((a.extCourseSectionId > b.extCourseSectionId) ? 1 : ((b.extCourseSectionId > a.extCourseSectionId) ? -1 : 0)));
        },
        filteredCourses() {
            const {
                courses, showLinked, showDeleted, filter,
            } = this;
            return courses.filter((course) => {
                if (showLinked && showDeleted) {
                    if (course.deleted && course.googleCourse) return true;
                    return false;
                }
                if (showDeleted) {
                    if (course.deleted) return true;
                    return false;
                }
                if (showLinked) {
                    if (course.googleCourse) return true;
                    return false;
                }
                if (course.deleted) return false;
                if (!filter) return true;
                return course.search.toLowerCase().includes(filter.toLowerCase());
            });
        },
        totalPages() {
            const { filteredCourses } = this;
            return Math.ceil(filteredCourses.length / this.perPage);
        },
        canCreateCourse() {
            const { user } = this;
            const { school, userPermissions } = user;
            const { role } = school;

            return ['School Admin'].includes(role) || userPermissions.canManageCourses;
        },
    },
    mounted() {
        const removeGoogleColumn = this.$_userMixins_isGoogleDisabled;
        if (removeGoogleColumn) {
            this.fields = this.fields
                .filter((f) => f.label !== 'Google Course')
                .map((f) => ({ ...f }));
        }
    },
    methods: {
        getCourseColorIndex(course) {
            let str = course.extCourseSectionId;
            let charForColor = str.charCodeAt(0); // first char from course
            if (course.extCourseSectionId.indexOf('-') > -1) {
                // eslint-disable-next-line prefer-destructuring
                str = course.extCourseSectionId.split('-')[1];
                charForColor = str.substr(-1); // last char from course, if nyc
            }
            return Math.floor(charForColor % 10); // 0-9 as return val
        },
        first() {
            this.currentPage = 1;
        },
        last() {
            this.currentPage = this.totalPages;
        },
        previous() {
            if (this.currentPage > 1) {
                this.currentPage -= 1;
            }
        },
        next() {
            if (this.currentPage < this.totalPages) {
                this.currentPage += 1;
            }
        },
        getFirstTeacherEmail(course) {
            const [teacher] = course.teachers;
            return teacher ? teacher.schoolEmail : null;
        },
        closeCreateCourse() {
            this.createCourseVisible = false;
        },
        closeCourseTransfer() {
            this.courseTransferVisible = false;
        },
    },
});
</script>

<style scoped>
td.cell-class {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    vertical-align: middle !important;
}
.google-button {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
</style>

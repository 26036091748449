<template>
<div
    v-if="createCourseVisible"
    class="modal modal-sticky-bottom-right modal-sticky-lg show"
    role="dialog"
    data-backdrop="false"
    aria-modal="true"
    style="display: block"
>
    <div class="modal-dialog">
        <div class="modal-content kt-inbox">
            <div class="kt-portlet">
                <div class="kt-portlet__head">
                    <div class="kt-portlet__head-label">
                        <span class="kt-portlet__head-icon kt-hidden">
                            <i class="la la-gear" />
                        </span>
                        <h3 class="kt-portlet__head-title">
                            Create a new Course
                        </h3>
                    </div>
                    <div class="kt-portlet__head-label float-right">
                        <a
                            href="#"
                            class="btn btn-icon"
                            @click.stop.prevent="closeModal"
                        >
                            <i class="la la-close" />
                        </a>
                    </div>
                </div>
                <div class="kt-portlet__body">
                    <div class="kt-section">
                        <div class="form-group row">
                            <div class="col-lg-6">
                                <label>Course ID</label>
                                <input
                                    v-model="course.extCourseId"
                                    type="text"
                                    class="form-control"
                                    placeholder="EAS, MAT, HES, etc."
                                    style="text-transform: uppercase;"
                                >
                                <span class="form-text text-muted">Course Code Identifier</span>
                            </div>
                            <div class="col-lg-6">
                                <label class="">Course Section</label>
                                <input
                                    v-model="course.extCourseSectionSubId"
                                    type="text"
                                    class="form-control"
                                    :class="{'is-invalid': matchingCourseSection}"
                                    placeholder="01, 02, 03, etc."
                                    style="text-transform: uppercase;"
                                >
                                <span
                                    v-if="matchingCourseSection"
                                    class="invalid-feedback"
                                >
                                    Course matching section already exists
                                </span>
                                <span
                                    v-else
                                    class="form-text text-muted"
                                >Course Section Number</span>
                            </div>
                        </div>

                        <div class="form-group">
                            <label>Course Name</label>
                            <input
                                v-if="matchingCourse"
                                v-model="matchingCourse.name"
                                type="text"
                                class="form-control"
                                :disabled="matchingCourse"
                                placeholder="English 9, Algebra 1, etc."
                            >
                            <input
                                v-else
                                v-model="course.name"
                                type="text"
                                class="form-control"
                                :disabled="matchingCourse"
                                placeholder="English 9, Algebra 1, etc."
                            >
                        </div>

                        <button
                            class="btn btn-primary pull-right"
                            :class="{'kt-spinner kt-spinner--sm kt-spinner--light': saving}"
                            :disabled="invalidCourse"
                            @click.stop.prevent="createCourse"
                        >
                            {{ saving ? 'Creating' : 'Create' }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import { mapState } from 'vuex';
import Types from '../store/Types';
import * as network from '../network';

export default {
    name: 'CreateCourseModal',
    components: {
    },
    mixins: [],
    props: {
        createCourseVisible: {
            type: Boolean,
            required: true,
        },
        closeCreateCourse: {
            type: Function,
            required: true,
        },
        courses: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            saving: false,
            course: {
                extCourseId: '',
                extCourseSectionSubId: '',
                name: '',
            },
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            school: (state) => state.user.school,
        }),
        courseSections() {
            return this.$store.state.database.courseSections;
        },
        matchingCourse() {
            const { courses, course } = this;
            const { extCourseId } = course;
            if (!extCourseId) return null;
            const courseSection = courses.filter((cs) => !cs.deleted).find((cs) => cs.extCourseSectionId.split('-')[0] == extCourseId);
            return courseSection || null;
        },
        matchingCourseSection() {
            const { courses, course } = this;
            const { extCourseId, extCourseSectionSubId } = course;
            if (!extCourseId || !extCourseSectionSubId) return null;
            const courseSection = courses.find((cs) => cs.extCourseSectionId == `${extCourseId}-${extCourseSectionSubId}`);
            return courseSection || null;
        },
        invalidCourse() {
            const { matchingCourse, matchingCourseSection, course } = this;
            if (matchingCourseSection) return true;
            const { extCourseId, extCourseSectionSubId, name } = course;

            if (!extCourseId || !extCourseSectionSubId) return true;
            if (!matchingCourse && !name) return true;
            return false;
        },
    },
    watch: {
        course: {
            handler() {
                this.course.extCourseId = this.course.extCourseId.replace(/[^a-z0-9]/gi, '').toUpperCase();
                this.course.extCourseSectionSubId = this.course.extCourseSectionSubId.replace(/[^a-z0-9]/gi, '').toUpperCase();
                this.course.name = this.course.name.replace(/[^ -~\s]/gi, '');
            },
            deep: true,
        },
    },
    methods: {
        closeModal() {
            this.saving = false;
            this.course = {
                extCourseId: '',
                extCourseSectionSubId: '',
                name: '',
            };
            this.closeCreateCourse();
        },
        createCourse() {
            const v = this;
            if (v.saving || v.invalidCourse) return;
            v.saving = true;

            const { school, course, matchingCourse } = v;
            const { schoolId, schoolTermId } = school;
            const { extCourseId, extCourseSectionSubId, name } = course;

            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                },
                body: {
                    courseSection: {
                        extCourseId,
                        extCourseSectionSubId,
                        name: matchingCourse ? matchingCourse.name : name,
                        courseId: matchingCourse ? matchingCourse.courseId : null,
                    },
                },
            };

            network.courseSections.createCourseSection(params, (err, res) => {
                v.saving = false;
                if (err) return v.showError(err);
                if (!res || !res.courseSection) return v.showError('No course section created');
                v.closeModal();
                const courseSections = [...v.courseSections, res.courseSection];
                v.$store.commit(Types.mutations.SET_DB_COURSE_SECTIONS, courseSections);
            });
        },
    },
};
</script>

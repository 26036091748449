var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.createCourseVisible
    ? _c(
        "div",
        {
          staticClass: "modal modal-sticky-bottom-right modal-sticky-lg show",
          staticStyle: { display: "block" },
          attrs: {
            role: "dialog",
            "data-backdrop": "false",
            "aria-modal": "true",
          },
        },
        [
          _c("div", { staticClass: "modal-dialog" }, [
            _c("div", { staticClass: "modal-content kt-inbox" }, [
              _c("div", { staticClass: "kt-portlet" }, [
                _c("div", { staticClass: "kt-portlet__head" }, [
                  _vm._m(0),
                  _c(
                    "div",
                    { staticClass: "kt-portlet__head-label float-right" },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-icon",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.closeModal.apply(null, arguments)
                            },
                          },
                        },
                        [_c("i", { staticClass: "la la-close" })]
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "kt-portlet__body" }, [
                  _c("div", { staticClass: "kt-section" }, [
                    _c("div", { staticClass: "form-group row" }, [
                      _c("div", { staticClass: "col-lg-6" }, [
                        _c("label", [_vm._v("Course ID")]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.course.extCourseId,
                              expression: "course.extCourseId",
                            },
                          ],
                          staticClass: "form-control",
                          staticStyle: { "text-transform": "uppercase" },
                          attrs: {
                            type: "text",
                            placeholder: "EAS, MAT, HES, etc.",
                          },
                          domProps: { value: _vm.course.extCourseId },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.course,
                                "extCourseId",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c("span", { staticClass: "form-text text-muted" }, [
                          _vm._v("Course Code Identifier"),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-lg-6" }, [
                        _c("label", {}, [_vm._v("Course Section")]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.course.extCourseSectionSubId,
                              expression: "course.extCourseSectionSubId",
                            },
                          ],
                          staticClass: "form-control",
                          class: { "is-invalid": _vm.matchingCourseSection },
                          staticStyle: { "text-transform": "uppercase" },
                          attrs: {
                            type: "text",
                            placeholder: "01, 02, 03, etc.",
                          },
                          domProps: { value: _vm.course.extCourseSectionSubId },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.course,
                                "extCourseSectionSubId",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm.matchingCourseSection
                          ? _c("span", { staticClass: "invalid-feedback" }, [
                              _vm._v(
                                " Course matching section already exists "
                              ),
                            ])
                          : _c(
                              "span",
                              { staticClass: "form-text text-muted" },
                              [_vm._v("Course Section Number")]
                            ),
                      ]),
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("Course Name")]),
                      _vm.matchingCourse
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.matchingCourse.name,
                                expression: "matchingCourse.name",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              disabled: _vm.matchingCourse,
                              placeholder: "English 9, Algebra 1, etc.",
                            },
                            domProps: { value: _vm.matchingCourse.name },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.matchingCourse,
                                  "name",
                                  $event.target.value
                                )
                              },
                            },
                          })
                        : _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.course.name,
                                expression: "course.name",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              disabled: _vm.matchingCourse,
                              placeholder: "English 9, Algebra 1, etc.",
                            },
                            domProps: { value: _vm.course.name },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.course,
                                  "name",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                    ]),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary pull-right",
                        class: {
                          "kt-spinner kt-spinner--sm kt-spinner--light":
                            _vm.saving,
                        },
                        attrs: { disabled: _vm.invalidCourse },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.createCourse.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.saving ? "Creating" : "Create") + " "
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-portlet__head-label" }, [
      _c("span", { staticClass: "kt-portlet__head-icon kt-hidden" }, [
        _c("i", { staticClass: "la la-gear" }),
      ]),
      _c("h3", { staticClass: "kt-portlet__head-title" }, [
        _vm._v(" Create a new Course "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }